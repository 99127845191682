import logo from './logo.svg';
import './App.css';
import BaseLayout from './features/Layout/BaseLayout';
import Navigation from './features/Navigation/Navigation';

function App() {
  return (
    <BaseLayout>
      <Navigation />
    </BaseLayout>
  );
}

export default App;
