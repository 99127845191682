import { makeAutoObservable } from "mobx";

export default class GameResultStore {
    constructor() {
        makeAutoObservable(this);
        this._final = 0;
        this._best = 0;
        this._toLow = [];
        this._toHigh = [];
        this._meanDiff = 0;
    }

    get final() {
        return this._final;
    }

    set final(value) {
        this._final = value;

        if (value > this._best) {
            this._best = value;
        }
    }

    get best() {
        return this._best;
    }

    get toLow() {
        return this._toLow;
    }

    set toLow(value) {
        this._toLow = value;
    }

    get toHigh() {
        return this._toHigh;
    }

    set toHigh(value) {
        this._toHigh = value;
    }

    get meanDiff() {
        return this._meanDiff;
    }

    set meanDiff(value) {
        this._meanDiff = value;
    }
}