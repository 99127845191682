import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import FullWidthContainer from "../../components/Layout/FullWidthContainer";
import Main from "./Main";
import {Box} from "@mui/material";

export default function BaseLayout({ children }) {
  return (
      <Box sx={{ display: 'flex', flexDirection: "column", height:"100vh", alignContent:"center" }}>
          <Box component={"header"} sx={{ height:120}}>
              <Header />

          </Box>
          <Box component={"main"}
               sx={{
                   flexGrow: 1,
                   width: 800,
                   overflow: 'auto',
                   margin:"auto"
               }}>
              <Main>{children}</Main>

          </Box>
          <Box component={"footer"} sx={{ height:50,
              width: 800,
              margin:"auto"
          }}>
              <Footer />
          </Box>
    </Box>
  );
}
