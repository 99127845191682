import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "../Home/Home";
import Game from "../Game/Game";
import AudioAnalysis from "../AudioAnalysis/AudioAnalysis";

export default function Navigation() {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/game" element={<Game/>}/>
            <Route path="/audioanalysis" element={<AudioAnalysis/>}/>
        </Routes>
    );
}
