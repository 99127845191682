import { makeAutoObservable } from "mobx";

export default class NewGameSettingsStore {
    constructor() {
        makeAutoObservable(this);
        this._speed = 80;
        this._level = 0;
        this._tuningFrequency = 440;
        this._instrumentTuning = 440;
        this._instrument = 0;
        this._diffLevel = 10;
        this._gameResult = [];
    }
    get speed() {
        return this._speed;
    }
    set speed(value) {
        this._speed = value;
    }
    get level() {
        return this._level;
    }
    set level(value) {
        this._level = value;
    }

    set gameResult(value) {
        this._gameResult = value;
    }
    get gameResult() {
        return this._gameResult;
    }

    set instrumentTuning(value) {
        console.log("set instrument tuning", value)
        this._instrumentTuning = value;
    }
    get instrumentTuning() {
        console.log("get instrument tuning", this._instrumentTuning)
        return this._instrumentTuning;
    }
    set tuningFrequency(value) {
        this._tuningFrequency = value;
    }
    get tuningFrequency() {
        return this._tuningFrequency;
    }
    set instrument(value) {
        this._instrument = value;
    }
    get instrument() {
        return this._instrument;
    }
    get diffLevel() {
        return this._diffLevel;
    }
    set diffLevel(value) {
        this._diffLevel = value;
    }
}