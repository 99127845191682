import { useEffect, useState } from "react";
import { useStore } from "../store/store";
import { useAudioAnalyser } from "../audio/useAudioAnalyser";
import { prototype } from "circular-buffer";
import { notes } from "../audio/pitches";

let timerId;


export function useGameState(initialNotes, onFinished) {
    const sampleRate = 16;
    const store = useStore();
    const [isStarted, setIsStarted] = useState(false);
    const audioAnalyser = useAudioAnalyser(handleAudioFrequency);
    const [notes, setNotes] = useState(
        initialNotes.map(note => ({ note, diff: 0, state: 0 }))
    );
    let notesCopy = [...notes];

    const [actualNoteInfo, setActualNoteInfo] = useState({ note: "", diff: 0 });

    let actualNoteInfos = notes[0];
    let actualNoteIndex = 0;
    let counter = 0;
    let metronomeCounter = 0;

    let internalNoteStates = initNoteStates();

    const [metronome, setMetronome] = useState(false);

    useEffect(() => {
        audioAnalyser.init();
    }, []);

    function init() {
        console.log("init");
        notesCopy = initialNotes.map(note => ({ note, diff: 0, state: 0 }));
        setActualNoteState(1);
        internalNoteStates = initNoteStates();
        updateNotes();
    }


    function initNoteStates() {
        return initialNotes.map(note => (
            {
                key: note.key,
                notes: [],
                ticks: getTicksFromActualNote(note.duration),
            }));
    }

    function start() {
        console.log("start");
        console.log(internalNoteStates)
        setIsStarted(true);
        console.log(store.newGameSettingsStore.instrumentTuning)
        audioAnalyser.setInstrumentTuning(store.newGameSettingsStore.instrumentTuning);
        let speed = (60 / store.newGameSettingsStore.speed) / sampleRate * 1000;
        console.log(speed);
        audioAnalyser.start(speed);
    }

    function stop() {
        console.log("stop");
        console.log(internalNoteStates)
        setIsStarted(false);
        audioAnalyser.stop();
    }


    function handleAudioFrequency(changes) {
        //console.log(changes);

        handleMetronome();

        setActualNoteInfo({ note: changes.note, diff: changes.difference });

        if (changes.pitch === -1) {
            internalNoteStates[actualNoteIndex].notes.push({ freq: -1, diff: -1 });
        } else if (changes.note.toLowerCase().replace("/", "") === internalNoteStates[actualNoteIndex].key.toLowerCase().replace("/", "")) {
            internalNoteStates[actualNoteIndex].notes.push({ freq: changes.pitch, diff: changes.difference });
        } else {
            console.log("wrong note", changes.note.toLowerCase().replace("/", ""), internalNoteStates[actualNoteIndex].key.toLowerCase().replace("/", ""))
            internalNoteStates[actualNoteIndex].notes.push({ freq: -1, diff: -1 });
        }

        if (internalNoteStates[actualNoteIndex].notes.length >= internalNoteStates[actualNoteIndex].ticks) {
            console.log(internalNoteStates[actualNoteIndex]);
            // ignore first and last values
            internalNoteStates[actualNoteIndex].notes.shift();
            internalNoteStates[actualNoteIndex].notes.pop();

            // count wrong notes
            let wrongNotes = internalNoteStates[actualNoteIndex].notes.filter(note => note.freq === -1);
            console.log(wrongNotes);
            if (wrongNotes.length > sampleRate / 2) {
                setActualNoteState(5);
            }
            else {
                let correctNotes = internalNoteStates[actualNoteIndex].notes.filter(note => note.freq !== -1);
                console.log(correctNotes);
                console.log(correctNotes.reduce((a, b) => a + b.diff, 0), correctNotes.length);
                let meanDiff = correctNotes.reduce((a, b) => a + b.diff, 0) / correctNotes.length;
                console.log(meanDiff);
                setActualNoteStateAndDiff(2, meanDiff);
            }
            actualNoteIndex++;



            // stop if all notes are played
            if (actualNoteIndex >= notes.length) {
                console.log("stop");


                //store.newGameSettingsStore.setGameResult(internalNoteStates);
                calculateAndSafeResult();
                stop();
                updateNotes();
                onFinished();
                return;
            }

            setActualNoteState(1);
            updateNotes();
        }
    }

    function calculateAndSafeResult() {
        let analyzedNotes = analyzeNotes({ notes: notesCopy });
        console.log(analyzedNotes);
        store.gameResultStore.final = analyzedNotes.percentCorrectNotes;
        store.gameResultStore.toLow = analyzedNotes.tooLowNotes;
        store.gameResultStore.toHigh = analyzedNotes.tooHighNotes;
        store.gameResultStore.meanDiff = analyzedNotes.meanDiff;
    }


    function setActualNoteState(state) {
        notesCopy[actualNoteIndex].state = state;
    }

    function setActualNoteStateAndDiff(state, diff) {
        notesCopy[actualNoteIndex].state = state;
        notesCopy[actualNoteIndex].diff = diff;
    }

    function updateNotes() {
        console.log("updateNotes");
        setNotes(notesCopy);
        notesCopy = [...notes];
    }

    function getTicksFromActualNote() {
        if (notes[actualNoteIndex].note.duration === "16") {
            return sampleRate / 4;
        }
        if (notes[actualNoteIndex].note.duration === "8") {
            return sampleRate / 2;
        }
        if (notes[actualNoteIndex].note.duration === "q") {
            return sampleRate;
        }
        if (notes[actualNoteIndex].note.duration === "h") {
            return sampleRate * 2;
        }
        if (notes[actualNoteIndex].note.duration === "w") {
            return sampleRate * 4;
        }

    }

    function handleMetronome() {
        if (store.gameEvaluationSettings._useMetronome === false) {
            setMetronome(false);
            return;
        }

        console.log(metronomeCounter)
        setMetronome(metronomeCounter <= sampleRate / 2);
        metronomeCounter++;
        if (metronomeCounter >= sampleRate) {
            metronomeCounter = 0;
        }
    }


    function analyzeNotes(notesObject) {
        // Counters for correct, too low, and too high notes
        var correctNotes = 0;
        var diffSum = 0;
        var diffCount = 0;
        var tooLowNotes = [];
        var tooHighNotes = [];

        // Iterate through all notes in the object
        notesObject.notes.forEach(function (note) {
            if (note.state === 2) { // If the state of the note is 2
                if (note.diff > -store.newGameSettingsStore.diffLevel && note.diff < store.newGameSettingsStore.diffLevel) { // If the note is correct
                    correctNotes++;
                } else if (note.diff < -store.newGameSettingsStore.diffLevel) { // If the note is too low
                    tooLowNotes.push(note.note.key);
                } else if (note.diff > store.newGameSettingsStore.diffLevel) { // If the note is too high
                    tooHighNotes.push(note.note.key);
                }
                diffSum += note.diff;
                diffCount++;
            }
        });

        // Percentage calculation of correct notes
        var percentCorrectNotes = (correctNotes / notesObject.notes.length) * 100;

        return {
            percentCorrectNotes: percentCorrectNotes,
            tooLowNotes: tooLowNotes.filter(onlyUnique),
            tooHighNotes: tooHighNotes.filter(onlyUnique),
            meanDiff: diffSum / diffCount
        };
    }

    return {
        init,
        start,
        stop,
        state: {
            notes: notes,
            metronome: metronome
        },
        isStarted,
        actualNoteInfo
    };
}

function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}