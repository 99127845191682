import React from "react";
import FullWidthContainer from "../../components/Layout/FullWidthContainer";
import { AppBar, Typography } from "@mui/material";

export default function Header() {
  return (
    <AppBar position="absolute">
      <Typography variant={"h3"} align={"center"}>
        Intonation Trainer
      </Typography>
    </AppBar>
  );
}
