import { useEffect, useState } from "react";
import { useStore } from "../store/store";
import AudioMotionAnalyzer from "audiomotion-analyzer";
import { getNearestNoteAndDifference } from "./pitches";
import { set } from "mobx";
import autoCorrelate from "./autocorrelate";

let timerId;
// global variable to save microphone stream
// https://stackoverflow.com/questions/69237143/how-do-i-get-the-audio-frequency-from-my-mic-using-javascript
let audioCtx;
let micStream;
let analyserNode;
let audioData;
let correlatedSignal;
let localMaxima;
let biquadFilter;

let intervalId;

export function useAudioAnalyser(handleFrequencyChange) {
    const store = useStore();
    let instrumentTuning = 440;

    const init = async () => {
        audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        analyserNode = audioCtx.createAnalyser();
        //analyserNode.fftSize = 4096;
        //analyserNode.maxDecibels = 0;
        //analyserNode.minDecibels = -100;
        //analyserNode.smoothingTimeConstant = 0;
        audioData = new Float32Array(analyserNode.fftSize);
        correlatedSignal = new Float32Array(analyserNode.fftSize);
        localMaxima = new Array(10);

        biquadFilter = audioCtx.createBiquadFilter();
        biquadFilter.type = "lowpass";
        biquadFilter.frequency.value = 10;
    };


    const createAudioStream = async () => {
        const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        });

        return audioStream;
    };

    const stop = () => {
        console.log("stop audio analyser")
        micStream.disconnect();
        clearInterval(intervalId);
    };

    const start = async (interval) => {
        const audioStream = await createAudioStream();

        micStream = audioCtx.createMediaStreamSource(audioStream);
        micStream.connect(analyserNode);
        //biquadFilter.connect(analyserNode);

        audioData = new Float32Array(analyserNode.fftSize);
        correlatedSignal = new Float32Array(analyserNode.fftSize);

        intervalId = setInterval(() => {
            analyserNode.getFloatTimeDomainData(audioData);

            let pitch = autoCorrelate(audioData, audioCtx.sampleRate);

            if (pitch < 4000) {
                const { note, difference } = getNearestNoteAndDifference(pitch, instrumentTuning);
                handleFrequencyChange({ pitch, note, difference });
            }
        }, (interval));
    };

    const setInstrumentTuning = (tuning) => {
        instrumentTuning = tuning;
    };

    return {
        init,
        start,
        stop,
        setInstrumentTuning
    }
}