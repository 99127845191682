import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import { useStore } from "../../functions/store/store";

const GameResult = ({ handleClose }) => {
  const store = useStore();

  return (
    <Box position={"absolute"} left={"50%"} top={"20%"} width={"800px"} marginLeft={"-400px"}>
      <Card sx={{ padding: "20px" }}>
        <CardContent>
          <Typography variant="h2" component="h2">
            Endergebnis
          </Typography>
          <Typography variant="h5" component="h5">
            Aktuell: {store.gameResultStore.final.toFixed(1)}%
          </Typography>
          <Typography variant="h5" component="h5">
            Bestes: {store.gameResultStore.best.toFixed(1)}%
          </Typography>
          <Typography variant="h5" component="h5">
            Durchschnittliche Abweichung: {store.gameResultStore.meanDiff.toFixed(2)} cent
          </Typography>
          <Divider />
          {store.gameResultStore.toLow.length > 0 && (
            <>
              <Typography variant="h5" component="h5">
                Zu tief:
              </Typography>
              <Typography variant="body1" component="p">
                {store.gameResultStore.toLow.map((note) => (
                  <span key={note}>{note} </span>
                ))}
              </Typography>
            </>
          )}
          {store.gameResultStore.toHigh.length > 0 && (
            <>
              <Typography variant="h5" component="h5">
                Zu hoch:
              </Typography>
              <Typography variant="body1" component="p">
                {store.gameResultStore.toHigh.map((note) => (
                  <span key={note}>{note} </span>
                ))}
              </Typography>
            </>
          )}
        </CardContent>
        <CardActions>
          <Button onClick={handleClose}>Fertig</Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default GameResult;
