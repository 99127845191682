import React, { useEffect, useRef, useState } from "react";
import ContentContainer from "../../components/Layout/ContentContainer";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { store } from "../../functions/store/store";
import { useNavigate, useNavigation } from "react-router-dom";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { useAudioAnalyser } from "../../functions/audio/useAudioAnalyser";
import CircularBuffer from "circular-buffer";
import { useGameState } from "../../functions/hooks/useGameState";

let counter = 0;

let circularBuffer = new CircularBuffer(10);

export default function AudioAnalysis() {
  const navigate = useNavigate();
  const [frequency, setFrequency] = useState(0);
  const [note, setNote] = useState("");
  const [difference, setDifference] = useState(0);
  const [chartData, setChartData] = useState([]);

  const handleFrequencyChange = (change) => {
    setFrequency(change.pitch);
    setNote(change.note);
    setDifference(change.difference);
  };

  const audioAnalyser = useAudioAnalyser(handleFrequencyChange);

  useEffect(() => {
    if (Math.abs(difference) < 60) {
      circularBuffer.enq({ frequency, counter: counter++, difference });
      setChartData(circularBuffer.toarray());
    }
  }, [frequency]);

  const handleBackToHome = () => {
    navigate("/");
  };

  const handleStart = () => {
    audioAnalyser.setInstrumentTuning(392);
    audioAnalyser.start((store.newGameSettingsStore.speed / 60 / 16) * 1000);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant={"h4"}>Screen for analysis of the audio Data</Typography>
        <Typography variant={"h6"}>Frequency: {frequency.toFixed(2)}</Typography>
        <Typography variant={"h6"}>Note: {note}</Typography>
        <Typography variant={"h6"}>Difference: {difference.toFixed(2)}</Typography>
      </CardContent>
      <CardActions>
        <Button size="large" variant={"contained"} onClick={handleBackToHome}>
          Back to Home!
        </Button>
        <Button size="large" variant={"contained"} onClick={() => audioAnalyser.init()}>
          Create Audio Analyser
        </Button>
        <Button size="large" variant={"contained"} onClick={handleStart}>
          Start AA
        </Button>
        <Button size="large" variant={"contained"} onClick={() => audioAnalyser.stop()}>
          Stop AA
        </Button>
      </CardActions>
      <CardContent>
        <LineChart width={800} height={300} data={chartData}>
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <YAxis type="number" domain={[0, 1000]} />
          <Line type="monotone" dataKey="frequency" stroke="#8884d8" />
        </LineChart>
        <LineChart width={800} height={300} data={chartData}>
          <YAxis type="number" domain={[-10, 10]} />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <Line type="monotone" dataKey="difference" stroke="#8884d8" />
        </LineChart>
      </CardContent>
    </Card>
  );
}
