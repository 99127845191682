import { makeAutoObservable } from "mobx";

export default class GameEvaluationSettings {
    constructor() {
        makeAutoObservable(this);
        this._useMetronome = true;
        this._showDirectColorFeedback = true;
        this._showTuning = false;
        this._summary = [];
    }

    get useMetronome() {
        return this._useMetronome;
    }

    set useMetronome(value) {
        this._useMetronome = value;
    }

    get showDirectColorFeedback() {
        return this._showDirectColorFeedback;
    }

    set showDirectColorFeedback(value) {
        this._showDirectColorFeedback = value;
    }

    get showTuning() {
        return this._showTuning;
    }

    set showTuning(value) {
        this._showTuning = value;
    }


}