import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  SpeedDial,
  Typography,
} from "@mui/material";
import { store } from "../../functions/store/store";
import { useNavigate, useNavigation } from "react-router-dom";
import Vex from "vexflow";
import Example from "./Example";
import { useGameState } from "../../functions/hooks/useGameState";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import GameResult from "./GameResult";

export default function Game() {
  const vfRef = useRef(null);
  const navigate = useNavigate();
  const [timerPlaying, setTimerPlaying] = useState(false);
  const [viewScore, setViewScore] = useState(false);
  const { start, stop, isStarted, state, actualNoteInfo, init } = useGameState(
    [
      { key: "C/4", duration: "q" },
      { key: "D/4", duration: "q" },
      { key: "E/4", duration: "q" },
      { key: "F/4", duration: "q" },
      { key: "G/4", duration: "q" },
      { key: "A/4", duration: "q" },
      { key: "B/4", duration: "q" },
      { key: "C/5", duration: "q" },

      { key: "B/4", duration: "q" },
      { key: "A/4", duration: "q" },
      { key: "G/4", duration: "q" },
      { key: "F/4", duration: "q" },
      { key: "E/4", duration: "q" },
      { key: "D/4", duration: "q" },
      { key: "C/4", duration: "q" },
    ],
    onFinished
  );

  //console.log(state);

  const handleBackToHome = () => {
    navigate("/");
  };

  const handleStart = () => {
    setTimerPlaying(false);
    start();
  };

  const startGame = () => {
    init();
    setTimerPlaying(true);
  };

  const handleStop = () => {
    stop();
  };

  function onFinished() {
    setViewScore(true);
  }

  useEffect(() => {
    // Clear previous content
    vfRef.current.innerHTML = "";

    const VF = Vex.Flow;
    const vf = new VF.Factory({ renderer: { elementId: vfRef.current.id, width: 1000 } });

    const renderer = new VF.Renderer(vfRef.current.id, VF.Renderer.Backends.SVG);
    renderer.resize(900, 100);

    const context = renderer.getContext();
    //context.setFont("Arial", 10, "").setBackgroundFillStyle("#fff");

    const stave = vf.Stave({ x: 0, y: 0, width: 850 });

    stave.addClef("treble").setContext(context);

    let notes = state.notes.map((noteData) => {
      //console.log(noteData);
      const { note, state, diff } = noteData;

      let color = getColorFromState(state, diff);

      let staveNote = new Vex.Flow.StaveNote({
        keys: [note.key],
        duration: note.duration,
      }).setStyle({
        fillStyle: color,
        strokeStyle: color,
      });

      if (note.key.includes("#")) {
        //staveNote.addAccidental(0, new Vex.Flow.Accidental("#"));
      } else if (note.key.includes("b")) {
        //staveNote.addAccidental(0, new Vex.Flow.Accidental("b"));
      }

      return staveNote;
    });

    var voice = new Vex.Flow.Voice({
      num_beats: 15,
      beat_value: 4,
      resolution: Vex.Flow.RESOLUTION,
    });

    voice.addTickables(notes);
    // Format and justify the notes to 500 pixels
    var formatter = new Vex.Flow.Formatter().joinVoices([voice]).format([voice], 750);

    stave.draw();
    voice.draw(context, stave);
  }, [state]);

  useEffect(() => {
    if (isStarted) {
      console.log("Game started");
    }
  }, [isStarted]);

  const startTime = 5;
  const getTimeSeconds = (time) => (startTime - time + 1) | 0;

  function getColorFromState(state, diff) {
    if (store.gameEvaluationSettings.showDirectColorFeedback === false) {
      switch (state) {
        case 1:
          return "blue";
        default:
          return "black";
      }
    }

    let color = "black";
    switch (state) {
      case 1:
        color = "blue";
        break;
      case 2:
        if (diff <= -store.newGameSettingsStore.diffLevel) {
          color = "orange";
        } else if (diff >= store.newGameSettingsStore.diffLevel) {
          color = "red";
        } else {
          color = "green";
        }
        break;
      case 5:
        color = "grey";
        break;
      // Add more cases as needed
      default:
        color = "black";
    }
    return color;
  }

  const renderTime = (dimension, time) => {
    return (
      <Box
        justifyContent={"space-around"}
        display={"flex"}
        textAlign={"center"}
        flexDirection={"column"}
      >
        <Box fontSize={"20px"}>Start in</Box>
        <Box fontSize={"50px"}>{time}</Box>
      </Box>
    );
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant={"h4"}>Action Time</Typography>
      </CardContent>
      <CardContent>
        <Grid item xs={12} container justifyContent={"start"} alignItems={"center"} spacing={2}>
          <Grid item xs={6}>
            {store.gameEvaluationSettings.showTuning && (
              <>
                <Typography variant={"body1"}>Aktuelle Note: {actualNoteInfo.note} </Typography>
                <Typography variant={"body1"}>Difference: {actualNoteInfo.diff} </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box visibility={state.metronome ? "visible" : "hidden"}>
              <SpeedDial ariaLabel="SpeedDial basic example"></SpeedDial>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Modal
          open={timerPlaying}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box position={"absolute"} top={100} left={"50%"}>
            <CountdownCircleTimer
              isPlaying={timerPlaying}
              duration={startTime}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[7, 5, 2, 0]}
              onComplete={() => handleStart()}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>{renderTime("seconds", getTimeSeconds(elapsedTime))}</span>
              )}
            </CountdownCircleTimer>
          </Box>
        </Modal>
        <Modal
          open={viewScore}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <GameResult handleClose={() => setViewScore(false)} />
        </Modal>
      </CardContent>
      <CardContent>
        <Box
          display="flex"
          alignItems="flex-start"
          position={"relative"}
          left={-300}
          overflow={"visible"}
          ref={vfRef}
          id={"vf"}
          width={1200}
        ></Box>
      </CardContent>
      <CardActions>
        {isStarted ? (
          <Button size="large" variant={"contained"} onClick={handleStop}>
            Stop
          </Button>
        ) : (
          <Button size="large" variant={"contained"} onClick={startGame}>
            Start
          </Button>
        )}
        <Button size="large" variant={"contained"} onClick={handleBackToHome}>
          Zurück zur Startseite!
        </Button>
      </CardActions>
    </Card>
  );
}
