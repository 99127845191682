import React, { useState } from "react";
import ContentContainer from "../../components/Layout/ContentContainer";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { store } from "../../functions/store/store";
import { useNavigate, useNavigation } from "react-router-dom";
import { observable } from "mobx";

const Home = () => {
  const [speed, setSpeed] = useState(store.newGameSettingsStore.speed);
  const [level, setLevel] = useState(store.newGameSettingsStore.level);
  const [useMetronome, setUseMetronome] = useState(store.gameEvaluationSettings.useMetronome);
  const [showTuning, setShowTuning] = useState(store.gameEvaluationSettings.showTuning);
  const [directFeedback, setDirectFeedback] = useState(
    store.gameEvaluationSettings.showDirectColorFeedback
  );
  const [tuningFrequency, setTuningFrequency] = useState(
    store.newGameSettingsStore.tuningFrequency
  );
  const [instrument, setInstrument] = useState(store.newGameSettingsStore.instrument);
  const navigate = useNavigate();

  const handleChangeLevel = (event) => {
    setLevel(event.target.value);
  };
  const handleChangeSpeed = (event) => {
    setSpeed(event.target.value);
  };

  const handleOnChallenge = () => {
    store.newGameSettingsStore.level = level;
    store.newGameSettingsStore.speed = speed;
    store.newGameSettingsStore.instrument = instrument;
    store.newGameSettingsStore.tuningFrequency = tuningFrequency;
    store.newGameSettingsStore.instrumentTuning = getInstrumentTuning(instrument);
    store.gameEvaluationSettings.useMetronome = useMetronome;
    store.gameEvaluationSettings.showTuning = showTuning;
    store.gameEvaluationSettings.showDirectColorFeedback = directFeedback;
    console.log(getInstrumentTuning(instrument));
    navigate("/game");
  };

  const handleChangeTuningFrequency = (event) => {
    setTuningFrequency(event.target.value);
  };

  const handleChangeInstrument = (event) => {
    let value = event.target.value;
    setInstrument(value);
  };

  const getInstrumentTuning = (value) => {
    console.log("get instrument tuning", value);
    switch (value) {
      case 0:
      case 3:
      case 4:
        return 392;
      case 1:
        return 440;
      case 2:
        return 523.25;
      default:
        return 440;
    }
  };

  const handleMetronomeChange = () => {
    setUseMetronome(!useMetronome);
  };

  const handleFeedbackChange = () => {
    setDirectFeedback(!directFeedback);
  };

  const handleShowTuningChange = () => {
    setShowTuning(!showTuning);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant={"h4"}>Starte ein neues Spiel</Typography>

        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} container justifyContent={"start"} alignItems={"center"} spacing={2}>
            <Grid item xs={2}>
              <Typography variant={"body2"}>Geschwindigkeit</Typography>
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput type={"number"} value={speed} fullWidth onChange={handleChangeSpeed} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"body2"}>bpm</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent={"start"} alignItems={"center"} spacing={2}>
            <Grid item xs={2}>
              <Typography variant={"body2"}>Level</Typography>
            </Grid>
            <Grid item xs={4}>
              <Select
                xs={12}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={level}
                label="Age"
                onChange={handleChangeLevel}
                fullWidth
              >
                <MenuItem value={0}>C-Dur</MenuItem>
                <MenuItem value={1}>Alle meine Entchen</MenuItem>
                <MenuItem value={2}>Wonderwall</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid item xs={12} container justifyContent={"start"} alignItems={"center"} spacing={2}>
            <Grid item xs={2}>
              <Typography variant={"body2"}>Grundfrequenz</Typography>
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                type={"number"}
                value={tuningFrequency}
                fullWidth
                onChange={handleChangeTuningFrequency}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"body2"}>Hz</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent={"start"} alignItems={"center"} spacing={2}>
            <Grid item xs={2}>
              <Typography variant={"body2"}>Instrument</Typography>
            </Grid>
            <Grid item xs={4}>
              <Select
                xs={12}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={instrument}
                label="Age"
                onChange={handleChangeInstrument}
                fullWidth
              >
                <MenuItem value={0}>Klarinette Bb</MenuItem>
                <MenuItem value={1}>Flöte C</MenuItem>
                <MenuItem value={2}>Saxophon Alt</MenuItem>
                <MenuItem value={3}>Trompete B</MenuItem>
                <MenuItem value={4}>Tenorhorn B</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="large" variant={"contained"} onClick={handleOnChallenge}>
          Spiel starten!
        </Button>
        <Button
          size="large"
          variant={"contained"}
          onClick={() => navigate("/audioanalysis")}
          style={{ visibility: "hidden" }}
        >
          Analyse Audio Data!
        </Button>
      </CardActions>
      <CardActions>
        <FormControlLabel
          control={<Checkbox checked={useMetronome} onChange={handleMetronomeChange} />}
          label="Metronom"
        />
        <FormControlLabel
          control={<Checkbox checked={directFeedback} onChange={handleFeedbackChange} />}
          label="Direktes Feedback"
        />
        <FormControlLabel
          control={<Checkbox checked={showTuning} onChange={handleShowTuningChange} />}
          label="Tuning"
        />
      </CardActions>
    </Card>
  );
};

export default Home;
