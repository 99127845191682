import GameEvaluationSettings from "./GameEvaluationSettings";
import GameResultStore from "./GameResultsStore";
import NewGameSettingsStore from "./NewGameSettingsStore";
import { createContext, useContext } from "react";

export const store = {
    newGameSettingsStore: new NewGameSettingsStore(),
    gameResultStore: new GameResultStore(),
    gameEvaluationSettings: new GameEvaluationSettings()
}


export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}